<template>
  <CBox mt="10px">
    <BackHeader />
    <CBox
      d="flex"
      :flexWrap="['wrap', 'nowrap', 'nowrap', 'nowrap']"
      alignItems="center"
    >
      <CBox d="flex" mr="auto" overflow="auto" alignItems="center" mb="8px">
        <router-link
          :to="{
            path: 'track-' + mode + '-all',
          }"
        >
          <CBox
            px="16px"
            py="8px"
            :bg="$route.params.category === 'all' ? 'bgButtonClub' : 'white'"
            :color="$route.params.category === 'all' ? 'white' : 'black'"
            whiteSpace="nowrap"
            cursor="pointer"
            borderRadius="5px"
            fontSize="12px"
            mr="6px"
            fontWeight="600"
            @click.native="selectedCategoryName = 'all'"
          >
            All
          </CBox>
        </router-link>
        <CBox v-for="(category, idx) in categories" :key="idx">
          <router-link
            v-if="
              category.type ===
              (mode === 'career' ? 'career_track' : 'skill_track')
            "
            :to="{
              path:
                'track-' +
                (category.type === 'career_track' ? 'career' : 'skill') +
                '-' +
                category.name.toLowerCase(),
            }"
          >
            <CBox
              p="8px"
              :bg="
                $route.params.category == category.name.toLowerCase()
                  ? 'bgButtonClub'
                  : 'white'
              "
              :color="
                $route.params.category == category.name.toLowerCase()
                  ? 'white'
                  : 'black'
              "
              whiteSpace="nowrap"
              cursor="pointer"
              borderRadius="5px"
              fontSize="12px"
              fontWeight="600"
              mr="6px"
              @click="handleChangeCategory(category.name.toLowerCase())"
            >
              {{ category.name }}
            </CBox>
          </router-link>
        </CBox>
      </CBox>
      <CBox>
        <CSelect
          v-model="mode"
          variant="outline"
          borderRadius="md"
          d="flex"
          ml="auto"
          fontSize="12px"
          mb="8px"
          fontWeight="600"
          @change="navigateToTrack"
          cursor="pointer"
        >
          <option value="career">Career Track</option>
          <option value="skill">Skill Track</option>
        </CSelect>
      </CBox>
    </CBox>
    <CBox
      v-if="selectedCategoryName === 'all'"
      bg="white"
      mt="8px"
      mb="16px"
      borderRadius="5px"
      :p="['16px', '24px', '24px', '24px']"
      boxShadow="0px 4px 10px rgba(0, 0, 0, 0.1)"
    >
      <CBox d="flex" align="center" alignItems="center">
        <CImage
          :src="require('@/assets/icon/info.svg')"
          alt="menu dashboard devcode"
          :w="['24px', '24px', '28px', '32px']"
          :h="['24px', '24px', '28px', '32px']"
          mr="14px"
          objectFit="cover"
        />
        <CText fontWeight="600" fontSize="16px" mr="8px">
          {{ mode === "career" ? "Career Track" : "Skill Track" }}</CText
        >
      </CBox>
    </CBox>
    <CBox
      v-else
      bg="white"
      mt="8px"
      mb="16px"
      borderRadius="5px"
      :p="['16px', '24px', '24px', '24px']"
      boxShadow="0px 4px 10px rgba(0, 0, 0, 0.1)"
    >
      <CBox d="flex" align="center" mb="16px" alignItems="center">
        <CImage
          :src="require('@/assets/icon/info.svg')"
          alt="menu dashboard devcode"
          :w="['24px', '24px', '28px', '32px']"
          :h="['24px', '24px', '28px', '32px']"
          mr="14px"
          objectFit="cover"
        />
        <CText fontWeight="600" fontSize="16px" mr="8px">{{
          selectedCategory.name
        }}</CText>
      </CBox>
      <CText fontWeight="400" fontSize="12px" white-space="pre-line">
        {{
          !showFullDescription &&
          selectedCategory.description.length > maxDescriptionLength
            ? truncatedDescription
            : selectedCategory.description
        }}
      </CText>
      <CBox d="flex" justifyContent="center">
        <CText
          v-if="
            !showFullDescription &&
            selectedCategory.description.length > maxDescriptionLength
          "
          @click.native="showFullDescription = true"
          color="bgButtonClub"
          fontSize="14px"
          fontWeight="600"
          mt="8px"
          py="8px"
          cursor="pointer"
          :_hover="{ opacity: '0.8' }"
        >
          Read More
        </CText>
      </CBox>
    </CBox>
    <CBox d="flex" flexWrap="wrap" justifyContent="space-between">
      <CBox
        v-if="
          (selectedCategoryName !== 'all' && tracks.length > 0) ||
          (selectedCategoryName === 'all' && allTracks.length > 0)
        "
        :w="['100%', '100%', '74%', '74%']"
      >
        <CBox
          v-for="(track, i) in selectedCategoryName !== 'all'
            ? tracks
            : allTracks"
          :key="track.id"
          bg="white"
          mb="16px"
          borderRadius="5px"
          :p="['16px', '24px', '24px', '24px']"
          boxShadow="0px 4px 10px rgba(0, 0, 0, 0.1)"
        >
          <CBox d="flex" justifyContent="space-between">
            <CText
              fontSize="16px"
              fontWeight="600"
              d="block"
              cursor="pointer"
              mb="8px"
              @click.native="$router.push(`/track/${track.slug}`)"
              >{{ track.name }}</CText
            >
            <CBox
              :w="['150px', '200px', '200px', '200px']"
              :d="['none', 'flex', 'flex', 'flex']"
              alignItems="center"
              justifyContent="space-between"
            >
              <CProgress color="vue" size="sm" :value="track.progress" />
              <CText ml="8px" fontSize="14px"
                >{{ track.progress.toFixed(2) }}%</CText
              >
            </CBox>
          </CBox>
          <CText
            fontSize="12px"
            mb="8px"
            v-html="
              showDescTrack.includes(i)
                ? track.description
                : truncateText(track.description, 200)
            "
          >
          </CText>
          <CBox d="flex" justifyContent="center">
            <CText
              v-if="
                !showDescTrack.includes(i) && track.description.length > 200
              "
              @click.native="handleShowDescTrack(i)"
              color="bgButtonClub"
              fontSize="14px"
              fontWeight="600"
              py="8px"
              cursor="pointer"
              :_hover="{ opacity: '0.8' }"
            >
              Read More
            </CText>
          </CBox>
          <CBox
            d="flex"
            :alignItems="['start', 'center', 'center', 'center']"
            :flexDirection="['column', 'row', 'row', 'row']"
          >
            <CBox d="flex" alignItems="center" mb="8px">
              <CBox
                bg="#00A790"
                color="white"
                w="80px"
                h="20px"
                borderRadius="34px"
                textAlign="center"
                mr="16px"
              >
                <CText
                  fontWeight="600"
                  :fontSize="['12px', '12px', '12px', '12px']"
                  textTransform="capitalize"
                >
                  {{ track.addtional_info.difficulty }}
                </CText>
              </CBox>
              <CBox d="flex" alignItems="center" mr="16px">
                <CImage
                  :src="require('@/assets/icon/icon-clock.svg')"
                  w="16px"
                  h="16px"
                  mr="4px"
                />
                <CText
                  fontWeight="400"
                  :fontSize="['12px', '12px', '12px', '12px']"
                  textTransform="capitalize"
                >
                  {{
                    track.addtional_info.estimated_time +
                    " " +
                    track.addtional_info.time_unit.toLowerCase()
                  }}</CText
                >
              </CBox>
              <CBox d="flex" alignItems="center" mr="16px">
                <CIcon color="main" name="book" fontSize="15px" mr="4px" />
                <CText
                  fontWeight="400"
                  :fontSize="['12px', '12px', '12px', '12px']"
                  textTransform="capitalize"
                >
                  {{ track.app_types.length }} Modules</CText
                >
              </CBox>
            </CBox>
            <CBox
              d="flex"
              alignItems="center"
              ml="auto"
              justifyContent="space-between"
              :w="['full', 'auto', 'auto', 'auto']"
            >
              <CBox
                :w="['150px', '200px', '200px', '200px']"
                :d="['flex', 'none', 'none', 'none']"
                alignItems="center"
                justifyContent="space-between"
              >
                <CProgress color="vue" size="sm" :value="track.progress" />
                <CText ml="8px" fontSize="14px"
                  >{{ track.progress.toFixed(2) }}%</CText
                >
              </CBox>
              <CButton
                size="0"
                px="16px"
                py="8px"
                ml="auto"
                mb="8px"
                :bg="
                  track.progress === 100
                    ? '#00A790'
                    : track.progress > 0
                    ? 'orange'
                    : 'bgButtonClub'
                "
                color="white"
                @click.native="$router.push(`/track/${track.slug}`)"
                fontSize="14px"
                :_hover="{ opacity: '0.8' }"
                >{{
                  track.progress === 100
                    ? "Completed"
                    : track.progress > 0
                    ? "On Progress"
                    : "Start Track"
                }}</CButton
              >
            </CBox>
          </CBox>
        </CBox>
      </CBox>
      <CBox v-else :w="['100%', '100%', '74%', '74%']">
        <CBox
          bg="white"
          mb="16px"
          borderRadius="5px"
          :p="['16px', '24px', '24px', '24px']"
          boxShadow="0px 4px 10px rgba(0, 0, 0, 0.1)"
          fontWeight="600"
        >
          COMING SOON
        </CBox>
      </CBox>
      <CBox :w="['100%', '100%', '25%', '25%']">
        <SidebarListTrack
          :total_track="
            selectedCategoryName !== 'all' && tracks.length > 0
              ? tracks.length
              : selectedCategoryName === 'all' && allTracks.length > 0
              ? allTracks.length
              : 0
          "
        />
      </CBox>
    </CBox>
  </CBox>
</template>

<script>
import { mapGetters } from "vuex";
import {
  GET_TRACK_LIST,
  CHANGE_CATEGORY,
  GET_ALL_TRACKS,
} from "@/store/track.module";
import BackHeader from "@/components/BackHeader";
import SidebarListTrack from "@/components/sidebar/ListTrack.vue";

const CATEGORY_MAP = {
  career_track: "Career Track",
  skill_track: "Skill Track",
};

export default {
  components: {
    BackHeader,
    SidebarListTrack,
  },
  metaInfo() {
    return {
      title: "Track",
      titleTemplate: "Devcode - %s",
    };
  },
  data() {
    return {
      CATEGORY_MAP,
      selectedCategoryName: "all",
      mode: "career",
      maxDescriptionLength: 200, // Jumlah karakter maksimum untuk deskripsi yang ditampilkan
      showFullDescription: false,
      showDescTrack: [],
    };
  },
  computed: {
    currentUser() {
      const user = this.$store.getters.currentUser;
      return user;
    },
    truncatedDescription() {
      return (
        this.selectedCategory.description.slice(0, this.maxDescriptionLength) +
        "..."
      );
    },
    ...mapGetters(["isLoading", "tracks", "categories", "allTracks"]),
    selectedCategory() {
      return this.categories.find(
        (category) =>
          category.name.toLowerCase() === this.$route.params.category
      );
    },
  },
  async mounted() {
    this.$store.dispatch(GET_TRACK_LIST);
    if (this.$route.params.type && this.$route.params.category) {
      this.mode = this.$route.params.type;
      this.selectedCategoryName = this.$route.params.category;
      await this.$store.dispatch(
        GET_ALL_TRACKS,
        this.mode === "career" ? "career_track" : "skill_track"
      );
      await this.$store.dispatch(
        CHANGE_CATEGORY,
        this.categories.find(
          (category) =>
            category.name.toLowerCase() === this.$route.params.category
        )
      );
    }
    this.mode = this.$route.params.type;
    await this.$store.dispatch(
      GET_ALL_TRACKS,
      this.mode === "career" ? "career_track" : "skill_track"
    );
  },
  methods: {
    async handleChangeCategory(categoryName) {
      const selectedCategory = this.categories.find(
        (category) => category.name.toLowerCase() === categoryName.toLowerCase()
      );

      if (selectedCategory) {
        this.selectedCategoryName = selectedCategory.name;
        this.mode =
          selectedCategory.type === "career_track" ? "career" : "skill";

        if (categoryName !== "all" || this.$route.params.category) {
          await this.$store.dispatch(CHANGE_CATEGORY, selectedCategory);
        } else {
          await this.$store.dispatch(
            GET_ALL_TRACKS,
            this.mode === "career" ? "career_track" : "skill_track"
          );
        }
      }
    },
    navigateToTrack() {
      this.selectedCategoryName = "all";
      if (this.mode === "career") {
        this.$router.push({ path: "track-career-all" });
      } else if (this.mode === "skill") {
        this.$router.push({ path: "track-skill-all" });
      }
      this.$store.dispatch(
        GET_ALL_TRACKS,
        this.mode === "career" ? "career_track" : "skill_track"
      );
    },
    truncateText(text, maxLength) {
      if (text && text.length > maxLength) {
        return text.slice(0, maxLength) + "...";
      }
      return text;
    },
    handleShowDescTrack(index) {
      if (this.showDescTrack.includes(index)) {
        this.showDescTrack = this.showDescTrack.filter(
          (item) => item !== index
        );
      } else {
        this.showDescTrack.push(index);
      }
    },
  },
};
</script>
